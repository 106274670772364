import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { Video } from '../../../utils/api/videoApi';
import { colors, spacing } from '../../../utils/styleguide';
import PrimaryButton, { ButtonColor } from '../buttons/PrimaryButton';
import Typography from '../text/Typography';
import VideoPlayButton from '../VideoPlayButton';
import { Col, Row } from './12ColGridLayout';

const Background = styled.section`
  background-color: ${(props: { backgroundColor?: string }) =>
    props.backgroundColor ?? colors.white};
  padding: ${spacing[11]}px 0;
`;

const StyledRow = styled(Row)`
  *:last-child {
    margin-bottom: 0;
  }
`;

const StyledText = styled.div`
  margin-bottom: ${spacing[6]}px;
  white-space: pre-line;
`;

type BigParagraphLayoutProps = {
  id?: string;
  overline?: ReactNode;
  title?: ReactNode;
  paragraph: ReactNode;
  buttonLabel?: string;
  href?: string;
  buttonColor?: ButtonColor;
  backgroundColor?: string;
  portraitVideo?: Video;
  landscapeVideo?: Video;
  className?: string;
};

const BigParagraphLayout = ({
  id,
  overline,
  title,
  paragraph,
  backgroundColor,
  buttonLabel,
  buttonColor = 'purple',
  href,
  portraitVideo,
  landscapeVideo,
  className,
}: BigParagraphLayoutProps) => {
  const hasVideo = portraitVideo || landscapeVideo;
  return (
    <Background backgroundColor={backgroundColor} id={id} className={className}>
      <StyledRow>
        <Col desktop="3 / span 8">
          {overline && (
            <StyledText>
              <Typography variant="overline">{overline}</Typography>
            </StyledText>
          )}

          {title && <StyledText>{title}</StyledText>}

          <StyledText>{paragraph}</StyledText>

          {buttonLabel && !hasVideo && (
            <PrimaryButton
              color={buttonColor}
              label={buttonLabel}
              tag="a"
              href={href}
              size="large"
              css={css`
                display: inline-block;
                margin-bottom: ${spacing[6]}px;
              `}
            />
          )}
          {hasVideo && (
            <VideoPlayButton
              buttonLabel={buttonLabel}
              portraitVideo={portraitVideo}
              landscapeVideo={landscapeVideo}
            />
          )}
        </Col>
      </StyledRow>
    </Background>
  );
};

export default BigParagraphLayout;
